<template>
  <b-modal size="sm" id="modalAirlineErstellen" centered title="Airline erstellen" @hide="onHide">
    <b-overlay :show="isLoading">
      <b-form-group label="Airline">
        <b-form-input v-model="airline" type="text" :formatter="formatAirlinekuerzel" />
      </b-form-group>
    </b-overlay>
    <template #modal-footer="{ hide }" class="pl-1">
      <b-button
        class="mr-4"
        @click="airlineErstellen(hide)"
        :disabled="airline?.length !== 2"
        :variant="airline?.length !== 2 ? 'secondary' : 'success'"
      >
        Erstellen
      </b-button>
      <b-button @click="hide">Abbrechen</b-button>
    </template>
  </b-modal>
</template>

<script>
import apiService from '@/core/common/services/api.service';

export default {
  data() {
    return {
      airline: null,
      isLoading: false,
    };
  },
  methods: {
    formatAirlinekuerzel(e) {
      return String(e).substring(0, 2);
    },
    onHide() {
      this.airline = null;
    },
    airlineErstellen(hide) {
      this.isLoading = true;
      apiService
        .post('/airline', { airlineKuerzel: this.airline })
        .then(result => {
          this.$emit('airlineErstellt', result);
        })
        .finally(() => {
          this.isLoading = false;
          hide();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.modal-footer) {
  padding: 5px;
}
</style>
